import React, { Fragment, useState, useEffect } from "react";
import { authInfo } from "../../components/authInfo";
import { postJson } from "../../utils/api-client";
import {
  toastSuccess,
  toastError,
  checkRequiredFields,
} from "../../utils/utils.js";
import { ErrorFallback } from "../../components/errorFallback";
import { Placeholder } from "../../components/placeholder";
import {
  ThemedButton,
  CancelButton,
} from "../../components/buttons/themedButton";
import { Modal } from "../../components/modal";
import { Validator } from "../../components/validator";

function TestEmailModal({ show, emailSettings, onClose }) {
  const [state, setState] = useState({
    status: "loaded",
    error: null,
    sending: false,
    validationErrors: [],
    fields: {
      emailAddress: "",
    },
  });

  const setStateValue = function (name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = function () {
    const values = state.fields;
    const validationErrors = {};
    checkRequiredFields(values, ["emailAddress"], validationErrors);
    const valid = Object.keys(validationErrors).length == 0;

    setState((prevState) => ({
      ...prevState,
      validationErrors: validationErrors,
    }));

    if (!valid) {
      toastError(
        "The information you entered could not be submitted. Please review the errors listed"
      );
    }

    return valid;
  };

  const handleInputChange = function (event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const fields = state.fields;
    fields[name] = value;
    setStateValue("fields", fields);
  };

  const handleSend = async () => {
    if (!validateForm()) {
      return;
    }

    const data = { ...emailSettings, ...state.fields };
    const resp = await postJson("/api/tools/testemail", data);

    if (resp.ok) {
      toastSuccess("Email sent!");
      onClose();
    } else {
      toastError("An error occurred sending an email.");
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="text-xl text-center font-bold pb-5 mb-5 border-b border-gray-200">
        Test Email
      </div>

      {state.status === "loading" ? (
        <Placeholder />
      ) : state.status === "error" ? (
        <div className="flex justify-center">
          <ErrorFallback error={state.error} />
        </div>
      ) : (
        <div>
          <div className="flex-auto">
            <div className={`flex`}>
              <label className={`flex-none`}>Email Address</label>
              <div className="flex-none mt-1">
                <input
                  type="text"
                  value={state.fields.emailAddress}
                  name="emailAddress"
                  onChange={handleInputChange}
                />
                <Validator
                  fieldName="emailAddress"
                  errorsList={state.validationErrors}
                ></Validator>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8 flex gap-x-2">
        <ThemedButton onClick={handleSend}>Send</ThemedButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </Modal>
  );
}

export { TestEmailModal };
