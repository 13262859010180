import React from "react";
import { PlusIcon as PlusIconSolid } from "@heroicons/react/solid";
import { FileInfoButton } from "./fileInfoButton";
import { ThemedCircleButton } from "../../components/buttons/themedButton";
import { getRecentCompletionDate } from "../../utils/utils.js";

const DocRequestList = ({ docRequests, onAddFile, onRemoveFile }) => {
  const onAddFileClick = ({ docKey, name }) => {
    if (onAddFile) {
      onAddFile({ docKey: docKey, name: name });
    }
  };

  const onRemoveFileClick = ({ docKey, name }) => {
    if (onRemoveFile) {
      onRemoveFile({ docKey: docKey, name: name });
    }
  };

  let recentCompletionDate = getRecentCompletionDate();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg sm:shadow">
            <div className="min-w-full divide-y divide-gray-200">
              <div className="bg-gray-50">
                <div>
                  <div className="px-2 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                    Requested Documents
                  </div>
                </div>
              </div>
              <div>
                {docRequests
                  .filter(
                    (ele) =>
                      ele.status === "Active" ||
                      (ele.status === "Complete" &&
                        ele.completionDate !== null &&
                        new Date(ele.completionDate) >= recentCompletionDate)
                  )
                  .map((itm, idx) => (
                    <DocRequestItem
                      key={itm.key}
                      itemIndex={idx}
                      docKey={itm.key}
                      status={itm.status}
                      name={itm.name}
                      files={itm.docFiles}
                      onAddFile={onAddFileClick}
                      onRemoveFile={onRemoveFileClick}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DocRequestItem = ({
  name,
  docKey,
  status,
  itemIndex,
  files = [],
  onAddFile,
  onRemoveFile,
}) => {
  const onPlusClick = () => {
    if (onAddFile) {
      onAddFile({ name: name, docKey: docKey });
    }
  };

  const onRemoveClick = (docKey) => {
    if (onRemoveFile) {
      onRemoveFile({ docKey: docKey, name: name });
    }
  };

  const cxname = itemIndex % 2 === 0 ? "bg-white" : "bg-gray-50";

  return (
    <div key={name} className={`${cxname} py-4 px-2`}>
      <div className={`flex justify-between`}>
        <div className="text-sm font-medium text-gray-900">{name}</div>
        <div className="flex-none">
          {status === "Active" && (
            <ThemedCircleButton onClick={onPlusClick}>
              <PlusIconSolid className="h-5 w-5" aria-hidden="true" />
            </ThemedCircleButton>
          )}
          {status === "Complete" && <div className="italic">Complete</div>}
        </div>
      </div>
      <div className="px-2 whitespace-nowrap text-sm font-medium text-gray-900 flex-1">
        <div className="flex flex-col sm:flex-row flex-wrap">
          {files.map((itm, idx) => (
            <FileInfoButton
              dockey={itm.key}
              {...itm}
              onRemoveClick={onRemoveClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { DocRequestList };
