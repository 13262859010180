import React, { useState, useEffect, Fragment, useContext } from "react";
import { Dropzone } from "./dropzone";
import { ProgressBar } from "./progressBar";
import { uploadFiles } from "../../utils/api-client";
import { Dialog, Transition } from "@headlessui/react";
import { ThemedButton } from "../../components/buttons/themedButton";
import { getLoginDetails } from "../../utils/utils.js";

const UploadModal = ({ show, name, onClose, docKey, maxFileSizeString }) => {
  const initialState = {
    files: [],
    uploading: false,
    errorsOccurred: false,
    cancellationToken: null,
  };
  const [state, setState] = useState(initialState);
  const { tenantInfo } = getLoginDetails();

  const filesPendingUpload = (files) => {
    return files.filter((f) => f.status === "pending");
  };

  const filesErrored = (files) => {
    return files.filter((f) => f.status === "error");
  };

  const onCancelFile = (fileItem) => {
    const newFiles = state.files.filter((itm) => itm !== fileItem);
    setState((prevState) => ({ ...prevState, files: newFiles }));
  };

  const onFilesAdded = (files) => {
    let combined = state.files;
    files.forEach((file) => {
      combined.push({
        file: file,
        status: "pending",
        percentage: 0,
        message: "",
      });
    });
    setState((prevState) => ({ ...prevState, files: combined }));
  };

  const onCancel = () => {
    if (state.cancellationToken != null) {
      state.cancellationToken.isCancelled = true;
    }
    onClose();
    resetState();
  };

  const onProgress = (fileUpdate) => {
    fileUpdate.file.status = fileUpdate.status;
    if (fileUpdate.percentage >= 0) {
      fileUpdate.file.percentage = fileUpdate.percentage;
    }
    if (fileUpdate.message) {
      fileUpdate.file.message = fileUpdate.message;
    }
    setState((prevState) => ({ ...prevState, files: state.files }));
  };

  const onComplete = (status) => {
    const pendingFiles = filesPendingUpload(state.files);
    if (status.successfullyUploaded && pendingFiles.length == 0) {
      onClose();
      resetState();
    } else {
      setState((prevState) => ({
        ...prevState,
        errorsOccurred: !status.successfullyUploaded,
        uploading: false,
      }));
    }
  };

  const onUpload = () => {
    state.cancellationToken = {
      isCancelled: false,
    };
    setState((prevState) => ({
      ...prevState,
      uploading: true,
      errorsOccurred: false,
    }));
    const newFiles = filesPendingUpload(state.files);
    uploadFiles(
      docKey,
      newFiles,
      maxFileSizeString,
      state.cancellationToken,
      onProgress,
      onComplete
    );
  };

  const resetState = () => {
    state.cancellationToken = null;
    setTimeout(() => {
      setState(initialState);
    }, 1000);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white rounded-lg align-middle px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full sm:max-w-xl sm:p-6">
              <div className="text-center font-bold">{name}</div>
              <div className="sm:border-t sm:border-gray-200 sm:pt-2">
                <div className="mt-1">
                  {filesErrored(state.files).length > 0 &&
                  state.errorsOccurred &&
                  !state.uploading ? (
                    <div className="text-red-600 p-2 mb-2">
                      One or more files could not be uploaded. Please note the
                      documents that were not uploaded and try again.
                    </div>
                  ) : (
                    ""
                  )}

                  <Dropzone
                    onFilesAdded={onFilesAdded}
                    showControl={state.files.length === 0}
                    linkColor={tenantInfo.buttonColor}
                    maxFileSizeString={maxFileSizeString}
                    allowOfficeDocuments={tenantInfo.allowOfficeDocuments}
                  >
                    <FileUploadList
                      files={state.files}
                      onCancelFile={onCancelFile}
                    />
                  </Dropzone>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex">
                {state.errorsOccurred &&
                filesPendingUpload(state.files).length === 0 ? (
                  <Fragment>
                    <ThemedButton onClick={onCancel}>Close</ThemedButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <button
                      type="button"
                      onClick={onCancel}
                      className="w-full mr-2 justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm"
                    >
                      Cancel
                    </button>
                    <ThemedButton
                      onClick={onUpload}
                      disabled={
                        filesPendingUpload(state.files).length === 0 ||
                        state.uploading
                      }
                    >
                      <div>
                        <span>Upload</span>
                      </div>
                      {state.uploading ? <SpinnerX /> : ""}
                    </ThemedButton>
                  </Fragment>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const FileUploadList = ({ files, onCancelFile }) => {
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border border-gray-200 rounded sm:rounded-lg">
          {files.map((itm, idx) => {
            return (
              <ProgressBar
                key={idx}
                fileItem={itm}
                index={idx}
                name={itm.file.name}
                state={itm.status}
                percentage={itm.percentage}
                message={itm.message}
                onCancel={onCancelFile}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SpinnerX = () => {
  return (
    <div className={"pl-4"}>
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-5 h-5 border-4 border-white-400 border-solid rounded-full animate-spin"
      />
    </div>
  );
};

export { UploadModal, FileUploadList };
