import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ThemedButton } from "../../components/buttons/themedButton";
import { ErrorModal } from "../../components/errorModal";
import { Placeholder } from "../../components/placeholder";
import { ErrorFallback } from "../../components/errorFallback";
import { DocumentTextIcon, RefreshIcon } from "@heroicons/react/solid";
import { DocuPointLayout } from "../../components/docupointLayout";
import BreadCrumb, {
  BreadCrumb_Accounts_Account,
} from "../../components/breadcrumb";
import { authInfo } from "../../components/authInfo";
import { STATUS_CODE } from "../../utils/api-client";

const fetchAccountDetails = async (account) => {
  const url = `api/fiaccounts/${account}`;
  const response = await fetch(url, {
    headers: authInfo.getAuthenticationHeaders(),
  });

  return response;
};

const useAccount = (account) => {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getAccountDetails = useCallback(async () => {
    setStatus("loading");
    const response = await fetchAccountDetails(account);

    if (response.status === STATUS_CODE.OK) {
      const responseData = await response.json();
      setData(responseData);
    } else if (response.status === STATUS_CODE.NOTFOUND) {
      setStatus("error");
      setError(`Sorry, account details for ${account} does not exists`);
    } else {
      setStatus("error");
      setData(null);
      setError("Error occurred retrieving account details");
    }

    setStatus("loaded");
  }, [account]);

  const refetch = () => {
    getAccountDetails();
  };

  useEffect(() => {
    getAccountDetails();
  }, [getAccountDetails]);

  return { status, error, data, refetch };
};

const CustomerRequestItem = ({ customerRequest }) => {
  return (
    <div className="detailsSection flex text-sm text-gray-500">
      <div className="flex-none pr-3">
        <DocumentTextIcon
          className="h-10 w-10 text-gray-300"
          aria-hidden="true"
        />
      </div>
      <div className="flex-1">
        <h3 className="font-medium text-gray-900">
          <Link to={`/requests/${customerRequest.key}`}>
            {customerRequest.requestName}
          </Link>
        </h3>
        <p>
          <time>
            {new Date(customerRequest.dateCreatedUtc).toLocaleDateString(
              "en-us",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            )}
          </time>
        </p>
        <div className="mt-4 flex space-x-2 space-y-2 flex-wrap">
          {customerRequest.accounts.map((account) => (
            <div className="inlineItem">{account.name}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

function AccountScreen() {
  const history = useHistory();
  const { account } = useParams();
  const [error, setError] = useState(null);
  const { status, error: fetchRequestError, data } = useAccount(account);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (status === "loaded") {
      {
      }
    }
  }, [status, data]);

  useEffect(() => {
    if (status === "error") {
      setError({ asage: fetchRequestError });
    }
  }, [status, fetchRequestError]);

  useEffect(() => {
    if (!!error) {
      setShowErrorModal(true);
    }
  }, [error]);

  return (
    <DocuPointLayout backgroundColor="bg-gray-100">
      {status === "loading" || !data ? (
        <Placeholder />
      ) : status === "error" ? (
        <div>
          <div className="flex justify-center">
            <ErrorFallback error={error} />
          </div>
          <div className="flex justify-center mt-10">
            <div>
              <ThemedButton onClick={reload}>
                <RefreshIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Try again
              </ThemedButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex w-full">
            <div className="grow">
              {data.customerRequests.length > 0 && (
                <>
                  <BreadCrumb_Accounts_Account account={data.account} />
                  {data.customerRequests.map((customerRequest) => (
                    <CustomerRequestItem customerRequest={customerRequest} />
                  ))}
                </>
              )}

              {data.customerRequests.length == 0 && (
                <>
                  <div className="mb-8">Account not found</div>
                  <Link to="/accounts">
                    <ThemedButton fitted={true}>Back to Accounts</ThemedButton>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showErrorModal && (
        <ErrorModal
          show={showErrorModal}
          errorMessage={error?.message}
          onClose={() => setShowErrorModal(false)}
        ></ErrorModal>
      )}
    </DocuPointLayout>
  );
}

export { AccountScreen };
