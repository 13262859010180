import React, { Fragment, useContext } from 'react';
import styles from "./index.module.css";
import { logout } from "../utils/utils.js"

const Footer = () => {
    return (
        <div className="mb-2">
            <div className={styles.footerText}>
                Powered by BankPoint. Copyright {new Date().getUTCFullYear()}.
            </div>
            <div className={`${styles.footerText} sm:hidden text-center`}>
                <a onClick={logout} className="mr-1 cursor-pointer">Sign out</a>|
                <a href="https://www.getbankpoint.com" onClick={logout} className="ml-1" target="_blank">getbankpoint.com</a>
            </div>
            <div className="flex justify-center">
                <img src="/logo_footer.png" alt="BankPoint Logo" className={styles.footerLogo} />
            </div>
        </div>
    )
};


export { Footer }