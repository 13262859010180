import React, { useEffect, useRef } from "react";
import { threeDots } from "../../utils/utils.js";

function ProgressBar({
  fileItem,
  index,
  name,
  state = "",
  percentage,
  onCancel,
  message,
}) {
  const barRef = useRef(null);
  const cancelRef = useRef(null);

  useEffect(() => {
    let percent = percentage || 0;
    barRef.current.style.width = `${percent}%`;
  });

  const onCancelClick = (evt) => {
    evt.preventDefault();
    if (onCancel) {
      onCancel(fileItem);
    }
  };

  const barColor = state === "error" ? "bg-red-500" : "bg-green-500";

  return (
    <div>
      <div
        className={`max-w-xl overflow-hidden p-1 sm:p-2 flex flex-row border-t-${
          index > 0 ? 2 : 0
        } `}
      >
        <div className="w-8 flex-initial self-center">
          <svg
            className="w-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="p-2 flex-1">
          <div>{name}</div>
        </div>
        <div
          className="w-8 flex-initial self-center cursor-pointer"
          onClick={onCancelClick}
        >
          <svg
            ref={cancelRef}
            className={`w-6 h-6 flex-initial self-center ${
              percentage == 0 || state === "error" ? "" : "hidden"
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div
        className={`h-3 relative m-1 sm:m-2 max-w-xl rounded-full overflow-hidden mt-2 bg-gray-300 ${
          state === "" || state === "pending" ? "hidden" : ""
        }`}
      >
        <div
          title={message}
          ref={barRef}
          id="bar"
          className={`h-full relative w-0 ${barColor}`}
        />
      </div>
      {state === "error" ? (
        <div className="px-1 sm:px-2 pb-1 text-gray-400">
          {threeDots(message, 200)}
        </div>
      ) : null}
    </div>
  );
}

export { ProgressBar };
